<template>
  <div>
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加教师</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <!-- <el-table-column align="center" type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="姓名" prop="lecturerName"></el-table-column>
        <el-table-column align="center" label="描述" prop="lecturerDesc"></el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <el-avatar :src="scope.row.lecturerImg" :size="100"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column align="center" label="封面图">
          <template slot-scope="scope">
            <el-image :preview-src-list="[scope.row.lecturerCoverImg]" style="height: 100px;"
                      :src="scope.row.lecturerCoverImg" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row)" icon="el-icon-delete" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <!-- 添加对话框 -->
    <el-dialog :title="form.id ? '编辑讲师' : '添加讲师'" :visible.sync="teacherShow" width="50%" @close="cancel">
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm" :rules="rules">
        <div class="layout_sb" style=" align-items: flex-start;">
          <el-form-item label="讲师名称:" prop="lecturerName">
            <el-input @focus="clearValidate('lecturerName')" placeholder="请输入讲师名称" v-model="form.lecturerName"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="讲师描述:" prop="lecturerDesc">
            <el-input @focus="clearValidate('lecturerDesc')" type="textarea" :rows="2" v-model="form.lecturerDesc"
                      placeholder="请输入讲师描述"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sb">
          <el-form-item label="讲师头像:" prop="lecturerImg">
            <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                       :on-success="successImages">
              <img v-if="form.lecturerImg" :src="form.lecturerImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="封面图:" prop="lecturerCoverImg">
            <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                       :on-success="successCoverImages">
              <img v-if="form.lecturerCoverImg" :src="form.lecturerCoverImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
            </el-upload>
          </el-form-item>
        </div>
        <!-- 富文本 -->
        <div style="display:flex;">
          <div class="name">讲师介绍:</div>
          <div style="flex:1;">
            <wang-editor :height="300" ref="editor"></wang-editor>
          </div>
        </div>
        <!-- 状态 -->
        <div class="status">
          <div class="name">讲师状态:</div>
          <el-switch v-model="switchStatus" active-text="启用" inactive-text="停用"></el-switch>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel">取 消</el-button>
        <el-button size="mini" type="primary" @click="resetForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WangEditor from "../../components/wangeditor/wangeditor.vue"
import {lecturerList, addOnSet, lecturerPage, lecturerDel} from "@/apis/course"

export default {
  components: {WangEditor},
  data() {
    return {
      tableData: [],
      query: {
        current: 1,
        size: 10,
      },
      total: null,
      teacherShow: false, // 添加对话框
      form: {
        lecturerName: '', // 讲师名字
        lecturerText: '', // 讲师介绍
        lecturerImg: '', // 讲师头像
        lecturerDesc: '', // 讲师描述
        lecturerCoverImg: '', // 讲师封面图片
        status: 0, // 讲师介绍
      },
      switchStatus: true,
      rules: {
        lecturerName: [{required: true, message: '请输入讲师名称', trigger: 'blur'}],
        lecturerDesc: [{required: true, message: '请输入讲师描述', trigger: 'blur'}],
        lecturerImg: [{required: true, message: '请输入讲师头像', trigger: 'blur'}],
        lecturerCoverImg: [{required: true, message: '请输入封面图', trigger: 'blur'}]
      },
      loading: false,
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 上传 讲师头像图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.lecturerImg = response.data
      this.$refs.form.clearValidate('lecturerImg');
    },
    // 讲师封面图片成功的钩子函数
    successCoverImages(response, fike, fileList) {
      this.form.lecturerCoverImg = response.data
      this.$refs.form.clearValidate('lecturerCoverImg');
    },
    // 获取分页
    async getData() {
      this.loading = true
      const res = await lecturerPage(this.query)
      if (res.code === 200) {
        const {records, total, current, size} = res.data
        this.tableData = records
        this.total = total
        this.query.current = current
        this.query.size = size
      }
      setTimeout(() => {
        this.loading = false
      }, 50)
    },

    // 确定编辑或添加
    async resetForm() {
      this.$refs.form.validate(async val => {
        if (!val) return
        this.switchStatus ? this.form.status = 0 : this.form.status = 1 // 是否禁用
        this.form.lecturerText = this.$refs.editor.getContent()
        if (!this.form.lecturerText) return this.$message({message: '请输入教师介绍', type: "warning"});
        const res = await addOnSet(this.form)
        if (res.code === 200) {
          this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: "success"});
          this.teacherShow = false
          this.getData()
          this.clearCen()
        }
      })
    },

    // 取消编辑或添加按钮
    cancel() {
      this.teacherShow = false
      this.switchStatus = true
      this.clearCen()
    },

    // 点击编辑按钮
    handlerEdit(item) {
      this.teacherShow = true
      this.$nextTick(() => {
        this.$refs.editor.setContent(item.lecturerText)
      })
      this.form = {...item}
      this.switchStatus = item.status == 0 ? true : false
    },
    // 删除
    handlerRemove(item) {
      this.$confirm('此操作将永久删除教师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const res = await lecturerDel({id: item.id})
        if (res.code === 200) {
          this.query.current = 1
          this.$message({message: '删除成功', type: "success"});
          this.getData()
        }
      }).catch(err => {
      })
    },

    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },

    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },

    // 初始化状态
    clearCen() {
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.$refs.editor.clearContent()
      delete this.form.id // 删除对象里面的id
      // 获取焦点对表单重新校验
      this.$refs.form.clearValidate('lecturerName');
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
  }
};
</script>

<style lang="less" scoped>
.el-form-item {
  flex: 1;
}

.name {
  display: flex;
  width: 100px;
  padding-right: 12px;
  justify-content: flex-end;
}

.status {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 60px;
  border: 1px dashed #999;
}

.avatar {
  width: 140px;
  height: 60px;
  display: block;
}
</style>