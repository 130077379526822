// 课程管理模块
import axios from 'axios'
import QS from 'qs'

// 教师分页
export const lecturerPage = params => {
    return axios.post(`/api/lecturer/page`, QS.stringify(params)).then(res => res.data)
}
// 教师列表
export const lecturerList = params => {
    return axios.post(`/api/lecturer/list`, QS.stringify(params)).then(res => res.data)
}

// 添加或编辑讲师
export const addOnSet = params => {
    return axios.post(`/api/lecturer/addOnSet`, QS.stringify(params)).then(res => res.data)
}

// 删除讲师
export const lecturerDel = params => {
    return axios.post(`/api/lecturer/del`, QS.stringify(params)).then(res => res.data)
}

// 课程类型分页
export const courseSortPage = params => {
    return axios.post(`/api/courseSort/page`, QS.stringify(params)).then(res => res.data)
}

// 添加课程类型
export const courseSortAddOnSet = params => {
    return axios.post(`/api/courseSort/addOnSet`, QS.stringify(params)).then(res => res.data)
}

// 删除课程类型
export const courseSortDel = params => {
    return axios.post(`/api/courseSort/del`, QS.stringify(params)).then(res => res.data)
}

// 添加或编辑线上课程 
export const courseAddOnSet = params => {
    return axios.post(`/api/course/addOnSet`, QS.stringify(params)).then(res => res.data)
}

// 课程类型
export const courseSortList = params => {
    return axios.post(`/api/courseSort/list`, QS.stringify(params)).then(res => res.data)
}

// 线上课程分页
export const coursePage = params => {
    return axios.post(`/api/course/page`, QS.stringify(params)).then(res => res.data)
}

// 删除线上课程
export const courseDel = params => {
    return axios.post(`/api/course/del`, QS.stringify(params)).then(res => res.data)
}

// 线上课程详情
export const getCourseId = params => {
    return axios.post(`/api/course/getCourseId`, QS.stringify(params)).then(res => res.data)
}

// 线上课程目录列表
export const catalogueList = params => {
    return axios.post(`/api/courseContents/CourseContentsList`, QS.stringify(params)).then(res => res.data)
}

// 添加或编辑线上课程目录列
export const catalogueAddOnSet = params => {
    return axios.post(`/api/courseContents/addOnSet`, QS.stringify(params)).then(res => res.data)
}

// 删除线上课程目录列
export const catalogueDel = params => {
    return axios.post(`/api/courseContents/del`, QS.stringify(params)).then(res => res.data)
}

// 线下课程 ---------

// 线下课程分页
export const offlineCoursePage = params => {
    return axios.post(`/api/offlineCourse/page`, QS.stringify(params)).then(res => res.data)
}
// 添加或编辑线下课程
export const offlineCourseAddOnSet = params => {
    return axios.post(`/api/offlineCourse/addOnSet`, QS.stringify(params)).then(res => res.data)
}
// 删除线下课程
export const del = params => {
    return axios.post(`/api/offlineCourse/del`, QS.stringify(params)).then(res => res.data)
}
// 获取线下期数
export const issueList = params => {
    return axios.post(`/api/offlineCourseIssue/issueList`, QS.stringify(params)).then(res => res.data)
}
// 获取线下报名列表
export const applyList = params => {
    return axios.post(`/api/offlineCourseApply/applyList`, QS.stringify(params)).then(res => res.data)
}
// 添加/编辑 线下课程期数
export const addOnsetIssue = params => {
    return axios.post(`/api/offlineCourseIssue/addOnsetIssue`, QS.stringify(params)).then(res => res.data)
}
// 线下课程期数报名列表
export const signList = params => {
    return axios.post(`/api/offlineCourseApply/applyList`, QS.stringify(params)).then(res => res.data)
}
